import React from 'react'
import shapeOne from '../../assets/shape-1.png'
import shapeTwo from '../../assets/shape-2.png'
import './Home.css'
import ScrollDown from './ScrollDown'

function Home() {
  return (
    <section className='home section' id='home'>
        <div className="home_wrapper">
            <div className='home_container container'>
                <div className="home_content">
                    <p className='home_subtitle text-cs'>
                        I am
                    </p>

                    <h1 className='home_title text-cs'>
                        <span>Deep</span> Shah
                    </h1>

                    <p className='home_job'>
                        <span className='text-cs'></span> <b>Web Developer</b>
                    </p>

                    <div className='home_img-wrapper'>
                        <div className="home_profile-img"></div>

                        <img src={shapeOne} alt='' className='shape shape_1' />
                        <img src={shapeTwo} alt='' className='shape shape_2' />
                        <img src={shapeTwo} alt='' className='shape shape_3' />
                    </div>

                    <p className="home_text">
                        A passionate Front-End Developer based in Boston, USA 📍
                    </p>

                    <div className='home_btns'>
                        <a href='#projects' className='btn text-cs btn-flex'>
                            See my work
                        </a>

                        <a href='#skills' className='hero_link text-cs'>
                            My Skills
                        </a>
                    </div>

                    <ScrollDown />
                </div>
            </div>
        </div>
        

        <div className="section_deco deco_left">
            <img src={shapeOne} alt="" className="shape" />
        </div>

        <div className="section_bg-wrapper">
            <span className="bg_title">Web Developer</span>
        </div>

        

    </section>
  )
}

export default Home
