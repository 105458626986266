import React from 'react'

import './Resume.css'
import shapeOne from '../../assets/shape-1.png'
import { RiGraduationCapFill } from "react-icons/ri";
import { MdWorkOutline } from "react-icons/md";
// import { SlCalender } from "react-icons/sl";
import { useState } from 'react'
// import { FaAngleDown } from "react-icons/fa";
import ColorsTimeline from './ColorsTimeline'

function Resume() {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

  return (
    <section className='resume section' id='resume'>
        <h2 className='section_title text-cs'>
            Qualification
        </h2>
        <p className='section_subtitle'>
            My <span>Story</span>
        </p>
            
        {/* NEW DESIGN */}

        <div className="resume_heading container">
            <button
                className={
                    toggleState === 1 
                        ? "resume_btn_active resume_btn btn-flex"
                        : "resume_btn btn-flex"
                }
                onClick={() => toggleTab(1)}
                >
                    <RiGraduationCapFill />
                    Education
            </button>

            <button
                className={
                    toggleState === 2 
                        ? "resume_btn resume_btn_active btn-flex"
                        : "resume_btn btn-flex"
                }
                onClick={() => toggleTab(2)}
                >
                    <MdWorkOutline />
                    Experience
            </button>

        </div>

        <div className="resume_container container">
            <div className="resume_sections">
                <div 
                className={
                    toggleState === 1
                    ? "resume_content resume_content_active"
                    : "resume_content"
               }>
                    <ColorsTimeline category="education"/>
                </div>

                <div 
                className={
                    toggleState === 2
                    ? "resume_content resume_content_active"
                    : "resume_content"
                }>
                    <ColorsTimeline category="experience" />

                </div>
            </div>
        </div>

        <div className="section_deco deco_left">
            <img src={shapeOne} alt="" className="shape" />
        </div>

        {/* <div className="section_bg-wrapper">
            <span className="bg_title">Resume</span>
        </div> */}

    </section>
  )
}

export default Resume