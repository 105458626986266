import React from 'react'
import './About.css'
import profileImg from '../../assets/profilepicture.png'
import shapeOne from '../../assets/shape-1.png'
import resume from '../../assets/Deep_Shah_Resume.pdf'
import { FaGithub, FaLinkedinIn, FaInstagram, FaDownload  } from 'react-icons/fa'

function About() {
  return (
    <section className="about section" id="about">
        <h2 className='section_title text-cs'>
            About Me
        </h2>
        <p className='section_subtitle'>
            My <span>Introduction</span>
        </p>

        <div className="about_container container grid">
            <img src={profileImg} alt="" className="about_img" />

            <div className="about_data">
                <p className="about_description">
                Passionate and versatile Full-Stack Developer with extensive experience building robust and scalable web apps. Technology has been an important part of my life since I was a child, and I am dedicated to using my skills to drive innovation and create meaningful solutions.
                </p>
                <br />

                <p className="about_description">
                With around 3 years of industry experience, I have developed my expertise in both Front-end and Back-end development through employment at Infosys Ltd. My experience includes contributions to a variety of projects, ranging from e-commerce systems to data-intensive applications.
                </p>

                <br />

                <p>
                I am passionate about creating seamless user experiences, and I excel at developing responsive and intuitive front-end interfaces while assuring solid and efficient back-end operations. My dedication to constant learning is demonstrated by my research of new technologies and approaches, which ensures that my skill set remains cutting-edge.
                </p>

                <div className='about_btns'>
                    <a href={resume} className='btn text-cs btn-flex' download="Resume_Deep_Shah" target='_blank' rel="noreferrer">
                        Download CV
                        <FaDownload />
                    </a>

                    <div className='about_socials'>
                        <a href='https://github.com/DeepShah1108' className='about_social_link'>
                            <FaGithub />
                        </a>
                        <a href='https://www.linkedin.com/in/deepshah11/' className='about_social_link'>
                            <FaLinkedinIn />
                        </a>
                        <a href='https://www.instagram.com/deep_shah118/' className='about_social_link'>
                            <FaInstagram />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="section_deco deco_right">
            <img src={shapeOne} alt="" className="shape" />
        </div>

        <div className="section_bg-wrapper">
            <span className="bg_title">About</span>
        </div>

    </section>

  )
}

export default About