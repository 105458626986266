import React from 'react'
import { FaArrowRight } from 'react-icons/fa';
import shapeTwo from '../../assets/shape-1.png';

function Items({ projectItems }) {
  return (
    <>
        {projectItems.map((projectItem) => {
            const { id, img, category, title, description, path, demoPath } = projectItem;
            return (
                <div className='project_items card card-two' key={id}>
                    <div className='project_img-wrapper'>
                        <img src={img} alt='' className='project_img'/>
                    </div>

                    <span className='project_category text-cs'>
                        {category}
                    </span>

                    <h3 className='project_title'>
                        {title}
                    </h3>

                    <p className='project_description'>
                        {description}
                    </p>

                    <a href={path} className='link'>
                        See Project
                        <FaArrowRight className='link_icon'></FaArrowRight>
                    </a>

                    {id === 3 || id === 4 ? 
                    (
                        <a href={demoPath} className='link'>
                            Demo
                            <FaArrowRight className='link_icon'></FaArrowRight>
                        </a>
                    ) : (
                        <>
                        </>
                    )}

                    

                    <img src={shapeTwo} alt='' className='shape c_shape'/>
                </div>
            )
        })}

    </>
  )
}

export default Items