import React from 'react'
import './Contact.css'
import { FaRegMap, FaRegEnvelope, FaRegAddressBook } from 'react-icons/fa'
import { useState } from 'react'
import shapeOne from '../../assets/shape-1.png'

function Contact() {
    const [form, setForm] = useState({
        name:'',
        email:'',
        message:''
    });

    const { name, email, message } = form;

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                'https://v1.nocodeapi.com/deepshah/google_sheets/FCxpwDEOBNZmnQbM?tabId=MyPortfolioData', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify([
                        [name, email, message, new Date().toLocaleString()]
                    ])
                }
            )
            await response.json();
            setForm({ ...form, name:"", email:"", message:"" })
            alert("Thank You. Will reach out soon!");
        } catch(err) {
            console.error(err);
        }
    }

  return (
    <section className='contact section' id='contact'>
        <h2 className='section_title text-cs'>
            Contact Me
        </h2>
        <p className='section_subtitle'>
            Lets <span>Connect</span>
        </p>

        <div className="contact_container container grid">
            <div className="contact_content">
                <div className="contact_card">
                    <span className="contact_card-icon">
                        <FaRegMap />
                    </span>

                    <h3 className="contact_card-title">
                        Address
                    </h3>
                    <p className="contact_card-data">
                        Boston, Massachussets, United States of America
                    </p>
                </div>

                <div className="contact_card">
                    <span className="contact_card-icon">
                        <FaRegEnvelope />
                    </span>

                    <h3 className="contact_card-title">
                        Email
                    </h3>
                    <p className="contact_card-data">
                        shah.deepaj@northeastern.edu
                    </p>
                </div>

                <div className="contact_card">
                    <span className="contact_card-icon">
                        <FaRegAddressBook />
                    </span>

                    <h3 className="contact_card-title">
                        Phone
                    </h3>
                    <p className="contact_card-data">
                        +1 857-313-2011
                    </p>
                </div>
            </div>

            

            <form className='contact_form' onSubmit={handleSubmit}>
                <div className='contact_form-group grid'>
                    <div className="contact_form-div">
                        <label className='contact_form-tag text-cs'>
                            Full Name <b>*</b>
                        </label>
                        <input type='text' name='name' value={name} onChange={handleChange} className='contact_form-input' required/>
                    </div>

                    <div className="contact_form-div">
                        <label className='contact_form-tag text-cs'>
                            Email <b>*</b>
                        </label>
                        <input type='text' name='email' value={email} onChange={handleChange} className='contact_form-input' required/>
                    </div>
                </div>

                <div className="contact_form-div contact_form-area">
                    <label className='contact_form-tag text-cs'>
                        Message <b>*</b>
                    </label>
                    <textarea name='message' value={message} onChange={handleChange} className='contact_form-input' required/>
                </div>

                <div className="contact_submit">
                    <button className='btn text-cs' onSubmit={handleSubmit}>
                        Send Message
                    </button>
                </div>
                

            </form>
        </div>

        <div className="section_deco deco_left">
            <img src={shapeOne} alt="" className="shape" />
        </div>

        <div className="section_bg-wrapper">
            <span className="bg_title">Contact</span>
        </div>

    </section>
  )
}

export default Contact