import Project1 from './assets/chronicles.png';
import Project2 from './assets/cloud.jpeg';
import Project3 from './assets/UXCaseStudy.png';
import Project4 from './assets/DesignSprint.png';

export const links = [
  {
    name: 'Home',
    path: 'home',
  },
  {
    name: 'About',
    path: 'about',
  },
  {
    name: 'Qualifications',
    path: 'resume',
  },
  {
    name: 'Projects',
    path: 'projects',
  },
  {
    name: 'Skills',
    path: 'skills',
  },
  {
    name: 'Contact',
    path: 'contact',
  },
];

export const skills = [
  {
    id: 1,
    name: 'HTML5',
    icon: "devicon-html5-plain colored"
  },

  {
    id: 2,
    name: 'CSS3',
    icon: "devicon-css3-plain colored"
  },

  {
    id: 3,
    name: 'Javascript',
    icon: "devicon-javascript-plain colored"
  },

  {
    id: 4,
    name: 'ReactJS',
    icon: "devicon-react-original colored"
  },

  {
    id: 5,
    name: 'Bootstrap',
    icon: "devicon-bootstrap-plain colored"
  },

  {
    id: 6,
    name: 'Sass',
    icon: "devicon-sass-original colored"
  },

  {
    id: 7,
    name: 'Figma',
    icon: "devicon-html5-plain colored"
  },

  {
    id: 8,
    name: 'Flask',
    icon: "devicon-flask-original"
  },

  {
    id: 9,
    name: 'Python',
    icon: "devicon-html5-plain colored"
  },

  {
    id: 10,
    name: 'Java',
    icon: "devicon-java-plain colored"
  },

  {
    id: 11,
    name: 'MySQL',
    icon: "devicon-mysql-original colored"
  },

  {
    id: 12,
    name: 'MongoDB',
    icon: "devicon-mongodb-plain colored"
  },

  {
    id: 13,
    name: 'PostgreSQL',
    icon: "devicon-postgresql-plain colored"
  },

  {
    id: 14,
    name: 'AWS',
    icon: "devicon-amazonwebservices-plain-wordmark colored"
  },

  {
    id: 15,
    name: 'Firebase',
    icon: "devicon-firebase-plain colored"
  },

  {
    id: 16,
    name: 'Heroku',
    icon: "devicon-heroku-plain colored"
  },

  {
    id: 17,
    name: 'Netlify',
    icon: "devicon-netlify-plain colored"
  },

  {
    id: 18,
    name: 'Git',
    icon: "devicon-git-plain colored"
  },
];

export const projects = [
  {
    id: 1,
    img: Project1,
    category: 'Web Development',
    title: 'NU Chronicles',
    description:
      'NUChronicles is a platform that helps students find affordable housing, clothes, groceries, and connect with alumni for mentorship. It also offers a marketplace for students to buy and sell used items and an events calendar to stay informed about campus life.',
    path: 'https://github.com/makarandmadhavi/NU-Chronicles'
  },
  {
    id: 2,
    img: Project2,
    category: 'Cloud Computing',
    title: 'Course Registration Application',
    description:
      'A Cloud-based Course Registration web app deploys on AWS using EC2 for scalable application servers, RDS for a relational database, SNS for event notifications, and Pulumi for infrastructure as code.',
    path: 'https://github.com/orgs/CSYE6225-ORG-DeepShah/repositories'
  },
  {
    id: 3,
    img: Project3,
    category: 'UX Case Study',
    title: 'Neighbourly',
    description:
      'People may feel cut off from their local neighborhood, businesses, and events. They may have difficulty locating information on the subject and connecting with people who share their passion.',
    path: 'https://www.figma.com/file/pKy51lxeryGKVGKCHXdOJB/DeepShah_Fall2023?type=design&node-id=477-845&mode=design',
    demoPath: 'https://www.figma.com/file/pKy51lxeryGKVGKCHXdOJB/DeepShah_Fall2023?type=design&node-id=499-2&mode=design'
  },
  {
    id: 4,
    img: Project4,
    category: 'Design Sprint, Conceptual',
    title: 'Adding tweet functionality within Amazon app',
    description:
      'Within the Amazon app design a feature—or set of features—that provides Twitter-like functionality. The goal is to implement Twitter like features into the Amazon ecosystem.',
    path: 'https://www.figma.com/file/pKy51lxeryGKVGKCHXdOJB/DeepShah_Fall2023?type=design&node-id=838-441&mode=design',
    demoPath: 'https://www.figma.com/file/pKy51lxeryGKVGKCHXdOJB/DeepShah_Fall2023?type=design&node-id=844-14&mode=design'
  },
];

export const resume = [
  {
    id: 1,
    title: 'Management Information Systems',
    subtitle: 'Master of Science at Northeastern University',
    date: '2014 - 2016',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    category: 'education',
  },

  {
    id: 2,
    title: 'Information Technology',
    subtitle: 'Bachelor of Engineering at University of Mumbai',
    date: '2012 - 2014',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    category: 'education',
  },

  {
    id: 4,
    title: 'Associate Software Developer',
    subtitle: 'Infosys Ltd.',
    date: '2020 - PRESENT',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    category: 'experience',
  },

  {
    id: 5,
    title: 'Software Developer',
    subtitle: 'Infosys Ltd.',
    date: '2018 - 2020',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    category: 'experience',
  },
];

