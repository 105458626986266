import React from 'react'
import './Projects.css'
import { projects } from '../../Data'
import Items from './Items';
import { useState } from 'react';
import shapeOne from '../../assets/shape-1.png'

function Projects() {
    const [projectItems] = useState(projects);

  return (
    <section className='projects section' id='projects'>
        <h2 className='section_title text-cs'>
            Projects
        </h2>
        <p className='section_subtitle'>
            My <span>Work</span>
        </p>

        <div className="projects_container container grid">
            <Items projectItems={projectItems} />
        </div>

        <div className="section_deco deco_right">
            <img src={shapeOne} alt="" className="shape" />
        </div>

        <div className="section_bg-wrapper">
            <span className="bg_title">Projects</span>
        </div>

        
    </section>
  )
}

export default Projects