import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FaAngleDown } from "react-icons/fa";
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import useMediaQuery from '@mui/material/useMediaQuery';

function ColorsTimeline({ category }) {

  const matches = useMediaQuery('(max-width:768px)');

  if(category === 'education') {
    return (
            <Timeline position={matches ? 'right' : 'alternate-reverse'} className="timeline_item">
              <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot sx={{backgroundColor: "var(--dot-color)"}}/>
                    <TimelineConnector sx={{backgroundColor: "var(--dot-color)"}} />
                </TimelineSeparator>
                <TimelineContent>
                  <Accordion 
                    sx={{
                      backgroundColor: "transparent",
                      border: "2px solid var(--border-color)",
                      borderRadius: "30px",
                      boxShadow: "var(--shadow)"
                  }}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color='var(--title-color)' />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div>
                        <Typography sx={{
                          fontSize: "var(--larger-font-size)",
                          color: "var(--primary-color)",
                          fontFamily: "var(--body-font)",
                          fontWeight: "500",
                          textAlign: "left"
                        }}>
                          Management in Information Systems
                        </Typography>
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left"
                        }}
                        >
                          Masters of Science at Northeastern University
                        </Typography>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bullets">
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left",
                          lineHeight: "1.43"
                        }}
                        >
                            <ul className='resume_list'>
                              <li>𝗖𝗼𝘂𝗿𝘀𝗲𝘀:
                                • Software Quality Control and Management
                                • User Experience Design and Testing
                                • Network Structure & Cloud Computing
                                • Advanced Big-Data Application & Indexing
                                • Data Management and Database Design
                                • Data Structures and Algorithms
                                • Web Design & User Experience
                                • Application Engineering Design
                              </li>
                            </ul>
                          </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot sx={{backgroundColor: "var(--dot-color)"}}/>
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                  <Accordion 
                    sx={{
                      backgroundColor: "transparent",
                      border: "2px solid var(--border-color)",
                      borderRadius: "30px",
                      boxShadow: "var(--shadow)"
                  }}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color='var(--title-color)' />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div>
                      <Typography sx={{
                          fontSize: "var(--larger-font-size)",
                          color: "var(--primary-color)",
                          fontFamily: "var(--body-font)",
                          fontWeight: "500",
                          textAlign: "left"
                        }}>
                          Information Technology
                        </Typography>
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left"
                        }}
                        >
                          Bachelor of Engineering at University of Mumbai
                        </Typography>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bullets">
                      <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left",
                          lineHeight: "1.43"
                        }}
                        >
                            <ul className='resume_list'>
                            <li>𝗖𝗼𝘂𝗿𝘀𝗲𝘀:
                                • Web Development
                                • Data Structures & Analysis
                                • Database Management System
                                • Cloud Computing & Services
                                • Artificial Intelligence
                                • Big Data Analytics
                              </li>
                            </ul>
                          </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                     
                </TimelineContent>
              </TimelineItem>
            </Timeline>
    )
  } else {
    return (
      <Timeline position={matches ? 'right' : 'alternate'} className="timeline_item">
              <TimelineItem>
                <TimelineSeparator >
                    <TimelineDot sx={{backgroundColor: "var(--dot-color)"}}/>
                    <TimelineConnector sx={{backgroundColor: "var(--dot-color)"}} />
                </TimelineSeparator> 
                <TimelineContent sx={matches ? { width: '100%' } : {}}>
                  <div>
                  <Accordion 
                    sx={{
                      backgroundColor: "transparent",
                      border: "2px solid var(--border-color)",
                      borderRadius: "30px",
                      boxShadow: "var(--shadow)"
                  }}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color='var(--title-color)' />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div>
                      <Typography sx={{
                          fontSize: "var(--larger-font-size)",
                          color: "var(--primary-color)",
                          fontFamily: "var(--body-font)",
                          fontWeight: "500",
                          textAlign: "left"
                        }}>
                          Software Engineer
                        </Typography>
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left"
                        }}
                        >
                          Infosys Ltd.
                        </Typography>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bullets">
                      <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left",
                          lineHeight: "1.43"
                        }}
                        >
                            <ul className='resume_list'>
                              <li>Improved legacy SQL system performance by 35% through schema normalization and indexing</li>
                              <li>Developed event-based Python FastAPI microservices with GCP cloud Pub/Sub, reducing request failures by 25%</li>
                              <li>Executed Jenkins and CI/CD pipelines, improving test coverage by 22% and securing deployments with hash checks</li>
                              <li>Enhanced the checkout flow using React-Query and Material-UI, achieving a 25% boost in user engagement</li>
                            </ul>
                          </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                     
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator >
                    <TimelineDot sx={{backgroundColor: "var(--dot-color)"}}/>
                    <TimelineConnector sx={{backgroundColor: "var(--dot-color)"}} />
                </TimelineSeparator> 
                <TimelineContent>
                  <div>
                  <Accordion 
                    sx={{
                      backgroundColor: "transparent",
                      border: "2px solid var(--border-color)",
                      borderRadius: "30px",
                      boxShadow: "var(--shadow)"
                  }}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color='var(--title-color)' />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div>
                      <Typography sx={{
                          fontSize: "var(--larger-font-size)",
                          color: "var(--primary-color)",
                          fontFamily: "var(--body-font)",
                          fontWeight: "500",
                          textAlign: "left"
                        }}>
                          Associate Software Engineer
                        </Typography>
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left"
                        }}
                        >
                          Infosys Ltd.
                        </Typography>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bullets">
                      <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left",
                          lineHeight: "1.43"
                        }}
                        >
                            <ul className='resume_list'>
                            <li>Orchestrated microservices deployment with Kubernetes and Docker, leveraging containerization for enhanced reliability</li>
                            <li>Developed a proactive system monitoring strategy utilizing Prometheus and Grafana, which reduced unexpected downtime by 60% and enabled real-time performance tracking</li>
                            <li>
                            Introduced "RxJS" and "NgRx" libraries to avoid duplication, enable reactive programming, and reduce app bundle size by approximately 29%
                            </li>
                            <li>
                            Implemented JWT and session management for secure, role-based access to internal company data
                            </li>
                            <li>
                            Encapsulated the tool in a Flask API, and containerized it with Docker for integration and deployment
                            </li>
                            </ul>
                          </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{backgroundColor: "var(--dot-color)"}}/>
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                  <Accordion 
                    sx={{
                      backgroundColor: "transparent",
                      border: "2px solid var(--border-color)",
                      borderRadius: "30px",
                      boxShadow: "var(--shadow)"
                  }}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color='var(--title-color)' />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div>
                      <Typography sx={{
                          fontSize: "var(--larger-font-size)",
                          color: "var(--primary-color)",
                          fontFamily: "var(--body-font)",
                          fontWeight: "500",
                          textAlign: "left"
                        }}>
                          Full Stack Developer
                        </Typography>
                        <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left"
                        }}
                        >
                          Kriit Technologies Ltd.
                        </Typography>
                      </div>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bullets">
                      <Typography sx={{
                          fontSize: "var(--normal-font-size)",
                          fontFamily: "var(--body-font)",
                          color: "var(--text-color)",
                          textAlign: "left",
                          lineHeight: "1.43"
                        }}
                        >
                            <ul className='resume_list'>
                            <li>Improved data security and customer trust by implementing HTTPS for REST APIs on an e-commerce site </li>
                            <li>Collaborated with senior developers on backend tasks using Python and Django, reducing data processing errors by 20% and improving data integrity</li>
                            <li>Created production-ready reusable components operating Angular, Typescript, and SCSS and wrote unit test cases using Jest to test the functionality of the components</li>
                            </ul>
                          </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                </TimelineContent>
              </TimelineItem>
      </Timeline>
    )
  }
  
}

export default ColorsTimeline
