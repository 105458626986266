import React from 'react'
import { FaGithub, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { BsMoon, BsSun } from 'react-icons/bs'
import './Header.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, animateScroll } from 'react-scroll/modules'
import shapeOne from '../../assets/shape-1.png'
import image from '../../assets/logo.png'
import { links } from '../../Data'


function Header() {

    const [showMenu, setShowMenu] = useState(false);
    
    const toggleTheme = () => {
        if(theme === 'light-theme') {
            setTheme('dark-theme')
        } else {
            setTheme('light-theme')
        }
    }

    const getStorageTheme = () => {
        let theme = 'light-theme';
        if(localStorage.getItem('theme')) {
            theme = localStorage.getItem('theme');
        }
    }

    const [theme, setTheme] = useState(getStorageTheme());

    useEffect(() => {
        document.documentElement.className = theme;
        localStorage.setItem('theme', theme);
    }, [theme]);

    const scrollTop = () => {
        animateScroll.scrollToTop();
    }

    // useEffect(() => {
    //     document.body.classList.toggle('no-scroll', showMenu);
    // }, [showMenu])

  return (
    <header className="header">
        <nav className="nav container">
            <Link to='/' onClick={scrollTop}>
                <img src={image} className="logo_img" alt='' />
            </Link>

            

            <div className={`${showMenu ? 'nav_menu show_menu': 'nav_menu'}`}>
                <ul className="nav_list grid text-cs">
                    {links.map(({ name, path }, index) => {
                        return (
                            <li className="nav_item" key={index}>
                            <Link
                                className='nav_link'
                                to={path}
                                spy={true}
                                hashSpy={true}
                                smooth={true} 
                                offset={-150} 
                                duration={500}
                                onClick={() => setShowMenu(!showMenu)}
                                >
                                {name}
                            </Link>
                        </li>
                        )
                    })}

                    <div className='header_socials'>
                        <a href='https://github.com/DeepShah1108' className='header_social_link'>
                            <FaGithub />
                        </a>
                        <a href='https://www.linkedin.com/in/deepshah11/' className='header_social_link'>
                            <FaLinkedinIn />
                        </a>
                        <a href='https://www.instagram.com/deep_shah118/' className='header_social_link'>
                            <FaInstagram />
                        </a>
                    </div>
                </ul>

                <div className="section_deco deco_left header_deco">
                    <img src={shapeOne} alt="" className="shape" />
                </div>

            </div>

            <div className="nav_btns">
                <div className="theme_toggler" onClick={toggleTheme}>
                    {theme === 'light-theme' ? <BsMoon /> : <BsSun />}
                </div>

                <div className={`${showMenu ? 'nav_toggle animate_toggle' : 'nav_toggle'}`} onClick={() => setShowMenu(!showMenu)}>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    </header>
  )
}

export default Header