import React from 'react'
import './Skills.css'
import { skills } from '../../Data'

function Skills() {

  return (
    <section className='skills section' id='skills'>
        <h2 className='section_title text-cs'>
            Professional Skills
        </h2>
        <p className='section_subtitle'>
            My <span>Talent</span>
        </p>

        <div className="skills_container container grid">
            {skills.map(({icon, name}, index) => {
                if (index === 6) {
                    return (
                        <div className="skills_logo" key={index}>
                            <img alt='' src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/figma/figma-original.svg" height={85} />
                            <p className="logo_text figma">
                                Figma
                            </p>
                        </div>
                    );
                }

                if (index === 8) {
                    return (
                        <div className="skills_logo" key={index}>
                            <img alt='' src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg" height={90} />
                            <p className="logo_text python">
                                Python
                            </p>
                        </div>
                    );
                }

                return (
                    <div className="skills_logo" key={index}>
                        <i style={{fontSize: 100}} className={icon}>
                            <p className="logo_text">
                                {name}
                            </p>
                        </i>
                    </div>
                )
            })}
        </div>

        {/* <div className="section_deco deco_left">
            <img src={shapeOne} alt="" className="shape" />
        </div> */}

        <div className="section_bg-wrapper">
            <span className="bg_title">Skills</span>
        </div>

    </section>
  )
}

export default Skills