import React from 'react'
import './Footer.css'
import { FaGithub, FaInstagram, FaLinkedinIn} from 'react-icons/fa'

function Footer() {
  return (
    <footer className="footer">
        <div className="footer_container container grid">
            <div className='footer_socials'>
                
                <a href='https://github.com/DeepShah1108' className='footer_social_link'>
                    <FaGithub />
                </a>
                <a href='https://www.linkedin.com/in/deepshah11/' className='footer_social_link'>
                    <FaLinkedinIn />
                </a>
                <a href='https://www.instagram.com/deep_shah118/' className='footer_social_link'>
                    <FaInstagram />
                </a>
            </div>

            <p className="footer_copyright text-cs">
                Made with ❤️ using React JS and MUI
            </p>

            <p className="footer_copyright text-cs">
                &copy; 2024 <span>Deep Shah</span>. All Rights Reserved
            </p>
        </div>
    </footer>
  )
}

export default Footer